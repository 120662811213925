import React from 'react';
import { Router } from '@reach/router';
import { SquareLanding } from '../components/routes';

const IndexPage = () => {
  return (
    <Router basepath="square">
      <SquareLanding default />
    </Router>
  );
};

export default IndexPage;
